/* 背景 */
.meet .background {
    opacity: 1;
    position: relative;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: #171717; 
    background-image: #171717;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
}

/* 浮动小窗 */
.meet .float-window {
    position: absolute;

    opacity: 1;
    z-index: 400;

    background: #222222;

    border-radius: 4px;
    overflow: hidden; 
}

/* 预览 video */
.meet .local-preview .video  {
    border: none;
}

.meet .local-preview .video:hover {
    box-shadow: none;
}

/* 本端 video */
.meet .local {
    top: 4rem;
    left: 1rem;
    width: 320px;
    height: 180px;

    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* 本端hover后显示边框阴影 */
.meet .local:hover {
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
    opacity: 1;
}

/* 本端的菜单 */
.meet .local .menu .operation{
    flex-direction: column;
}

.meet .local .menu:hover {
    background-color: rgba(0,0,0,0.3);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/* 本端video框 */
.meet .local .video {
    border: none;
}

/* 电子白板框 */
.meet .board {
    top: 4rem;
    right: 1rem;
    width: 320px;
    height: 180px;

    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* video框 */
.meet .video {
    z-index: 1;
    border: 1px solid #ffffff7f;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* video框hover后 */
.meet .video:hover {
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
    opacity: 1;
}

/* video框上菜单操作 */
.meet .menu {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 202;
}

/* video框上菜单下操作的div层*/
.meet .menu .operation  {
    width: 100%;
    height: 100%;
    opacity: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.meet .menu .operation .col {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/* video框上菜单，当hover后操作的div层（显示出来） */
.meet .menu:hover .operation {
    opacity: 1;
    z-index: 500;
}

/* video框上菜单下操作的icon */
.meet .menu .icon {
    opacity: 0.65;
    font-size: 2rem;
    border-radius: 50%;
}

/* video框上菜单下操作的icon */
.meet .menu .icon:hover {
    cursor: pointer;
    opacity: 1;
    transform: scale(1.2, 1.2);
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
}

/* video框上菜单下操作的icon说明 */
.meet .menu .description  {
    margin-top: 0.5rem;
    color: #fff;
    user-select: none; 
}

/* video框上菜单下的终端昵称 */
.meet .nickname {
    position: absolute;
    z-index: 100;
    padding: 0 0.5rem;

    width: fit-content;
    height: 2rem;
    line-height: 2rem;

    /* 位置 */
    float: right;
    bottom: 1rem;
    right: 1rem;

    border-radius: 4px;

    background-color: rgba(255, 255, 255, 0.65);
    color: #171717;

    /* 字体溢出省略 */
    overflow: hiddden;
    text-overflow: ellipsis;
    white-space: nowrap;

    display: flex;
    align-items: center;

    transition: height 0.5s ease-in-out 2s;
}



/* 显示（使用动画） */
.meet .show {
    opacity: 1!important;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* 隐藏（使用动画） */
.meet .hidden {
    opacity: 0!important;
    height: 0!important;
    width: 0!important;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* 没有数据（视频或电子白板）时显示的蒙层 */
.meet .obscuration {
    position: absolute;
    z-index: 200;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);

    top:50%;
    transform:translateY(-50%);

    text-align: center;
    transform-origin: center center;
    background-color: #171717;
}

/* 没有数据（视频或电子白板）时显示的封面 */
.meet .poster {
    position: absolute;
    display: inline-block;
    z-index: 201;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);

    top:50%;
    transform:translateY(-50%);

    text-align: center;
    transform-origin: center center;
}

/* 远端视频滑窗布局 */
.meet .remotes-warpper {
    position: absolute;
    width: 100%;
    bottom: 4rem;
    left: 0;
    z-index: 203;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.meet .remotes-warpper .remotes .menu {
    width: 320px;
    height: 180px;
    display: inline-block;

    transition: none;
}

.meet .remotes-warpper .remotes .menu:hover {
    background-color: rgba(0,0,0,0.3);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    width: 320px;
    height: 180px;
}

.meet .remotes-warpper .remotes .menu .operation {
    transition: none;
}

.meet .remotes-warpper .remotes .menu .description {
    line-height: 32px;
}

.meet .remotes-warpper .remotes .menu:hover .operation {
    opacity: 1;
    z-index: 500;
}

.meet .remotes-warpper .remotes .video {
    height: 180px;
    width: 320px;
}

.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote0,
.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote1,
.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote2,
.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote3,
.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote4,
.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote5,
.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote6,
.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote7,
.meet .remotes-warpper .remotes .slider-wapper .slider .middle .content .remote8 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    position: relative;

    width: 322px;
    height: 182px;
}

/* 浮动布局（视频为底） */
.meet .video-overlap {
    position: relative;
}

.meet .video-overlap .board {
    top: 4rem;
    right: 1rem;
    width: 320px;
    height: 180px;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.meet .video-overlap .video {
    border: none;
}

.meet .video-overlap .remote0,
.meet .video-overlap .remote1,
.meet .video-overlap .remote2,
.meet .video-overlap .remote3,
.meet .video-overlap .remote4,
.meet .video-overlap .remote5,
.meet .video-overlap .remote6,
.meet .video-overlap .remote7,
.meet .video-overlap .remote8 {
    z-index: -1;
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* 浮动布局（电子白板为底） */
.meet .board-overlap {
    position: relative;
}

.meet .board-overlap .board {
    z-index: -1;
}


.meet .board-overlap .board .show {
    opacity: 1;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.meet .board-overlap .board .hidden {
    opacity: 0;
    height: 0;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.meet .board-overlap .remotes {
    position: absolute;
    top: 4rem;
    right: 1rem;

    width: 320px;
    height: 180px;
    z-index: 100;

    background: #222222;

    border-radius: 4px;
    overflow: hidden; 
}

.meet .board-overlap .remotes:hover {
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);
}

.meet .board-overlap .remotes:hover .icon {
    opacity: 1;
    z-index: 300;

    -webkit-transition:opacity .5s ease-in-out;
    -moz-transition:opacity .5s ease-in-out;
    -o-transition:opacity .5s ease-in-out;
    -ms-transition:opacity .5s ease-in-out;
    transition:opacity .5s ease-in-out;
}

/* 四分屏 */
.meet .video-split-4 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 50%;
}

.meet .video-split-4 .remote0,
.meet .video-split-4 .remote1,
.meet .video-split-4 .remote2,
.meet .video-split-4 .remote3,
.meet .video-split-4 .board {
    position: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    margin: 0 auto;
    vertical-align: middle;
}

.meet .video-split-4 .remote0 .obscuration,
.meet .video-split-4 .remote3 .obscuration  {
    background-color: #171717;
    border: 1px solid #ffffff7f;
}

.meet .video-split-4 .remote1 .obscuration,
.meet .video-split-4 .remote2 .obscuration  {
    background-color: #222222;
    border: 1px solid #ffffff7f;
}

.meet .video-split-4 .menu:hover{
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);

    background-color: rgba(0,0,0,0.3);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/* 九分屏 */
.meet .video-split-9 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 33.3%;
}

.meet .video-split-9 .remote0,
.meet .video-split-9 .remote1,
.meet .video-split-9 .remote2,
.meet .video-split-9 .remote3,
.meet .video-split-9 .remote4,
.meet .video-split-9 .remote5,
.meet .video-split-9 .remote6,
.meet .video-split-9 .remote7,
.meet .video-split-9 .remote8,
.meet .video-split-9 .board {
    position: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    margin: 0 auto;
    vertical-align: middle;
}

.meet .video-split-9 .remote0 .obscuration,
.meet .video-split-9 .remote2 .obscuration,
.meet .video-split-9 .remote4 .obscuration,
.meet .video-split-9 .remote6 .obscuration,
.meet .video-split-9 .remote8 .obscuration{
    background-color: #171717;
    border: 1px solid #ffffff7f;
}

.meet .video-split-9 .remote1 .obscuration,
.meet .video-split-9 .remote3 .obscuration,
.meet .video-split-9 .remote5 .obscuration,
.meet .video-split-9 .remote7 .obscuration {
    background-color: #222222;
    border: 1px solid #ffffff7f;
}

.meet .video-split-9 .menu:hover {
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);

    background-color: rgba(0,0,0,0.3);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}


.meet .footer {

}