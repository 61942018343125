.rtc .ant-modal-header .title .icon {
    margin-right: 1rem;
}

.rtc .ant-modal-body {
    padding: 0.5rem 0;
}

.rtc .content .tab .title .icons .audio {
    font-size: 18px;
    margin-right: 0;
}

.rtc .content .tab .title .icons .video {
    font-size: 16px;
    margin-right: 2px;
}

.rtc .content .tab .title .icons .right {
    font-size: 16px;
}

.rtc .content .tab .title .icons .sender {
    color: #ff4d4f;
}

.rtc .content .tab .title .icons .receiver {
    color: #73d13d;
}
