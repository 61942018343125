
.show {
    opacity: 1;
    height: 100%;
    transform: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.hidden {
    opacity: 0;
    height: 0;
    transform: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.setting .ant-descriptions-row,
.setting .ant-descriptions-row .operation .ant-btn{
    border: 0;
    height: 2rem;
}

.setting .ant-descriptions-row .expire {
    display: flex;
}

.setting .ant-descriptions-row .expire .deadline {
    margin-right: 0.5rem;
}

.setting .ant-descriptions-row .expire .remaining {
    display: flex;
    margin: 0 0.25rem;
}

.setting .ant-descriptions-row .expire .remaining .ant-statistic-title {
    color: inherit;
    margin-right: 0.5rem;
}

.setting .ant-descriptions-row .expire .remaining .ant-statistic-content {
    color: inherit;
    font-size: 14px;
}


.setting .ant-modal-header .title .icon {
    margin-right: 1rem;
}

.setting .ant-modal-body {
    padding: 0;
}

.setting .ant-modal-body .descriptions .ant-descriptions-row > td {
    padding-bottom: 0;
}

.setting .ant-modal-body .descriptions .ant-descriptions-item-label {
    width: 7rem;
}

/* tabs */
.setting .tabs .ant-tabs-tab {
    margin: 0!important;
    padding: 1rem 2rem 1rem 1.5rem!important;
}

.setting .tabs .ant-tabs-tab .anticon {
    margin-right: 0.5rem;
}

.setting .tabs .tabpane {
    display: -webkit-box;
    width: 4rem;
}

.setting .tabs .tabpane-content {   
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.5rem;
}

.setting .tabs .tabpane-content::-webkit-scrollbar {
    display: none; 
    width: 0 !important;
}

.setting .tabs .tabpane-content-microphone .progress-volume {
    margin: 0 1rem 1rem 0;
}

.setting .tabs .tabpane-content-base .descriptions{
    margin: 1rem 1rem 0.5rem 0;
    padding: 1rem;
    border-radius: 0.5rem;
    background: #f5f5f5;
}


.setting .tabs .tabpane-content-camera .video-col,
.setting .tabs .tabpane-content-microphone .icon-col,
.setting .tabs .tabpane-content-loudspeaker .icon-col {
    margin: 1rem 0;
}

.setting .tabs .tabpane-content-camera .video-col .video-wapper .video {
    border: 1rem solid #f5f5f5;
    border-radius: 0 0 0.5rem 0.5rem ;
}

/* 显示名称 */
.setting .tabs .tabpane-content-camera .name,
.setting .tabs .tabpane-content-microphone .name,
.setting .tabs .tabpane-content-loudspeaker .name{
    display: block;
    border-radius: 0.5rem 0.5rem 0 0;
    background: #f5f5f5;
    height: 100%;
    width: 100%;
    padding: 0.5rem 1rem;
}

/* 图标 */
.setting .tabs .tabpane-content-microphone .icon-wapper,
.setting .tabs .tabpane-content-loudspeaker .icon-wapper {
    position: relative;
    overflow: hidden; 
    height: 100%;
    width: 100%;
}

.setting .tabs .tabpane-content-microphone .icon-wapper .icon,
.setting .tabs .tabpane-content-loudspeaker .icon-wapper .icon {
    border-radius: 0 0 0.5rem 0.5rem;
    background: #f5f5f5;
    height: 100%;
    width: 100%;
    vertical-align: middle; 
    overflow: hidden; 
    padding: 2rem;
    color: #40a9ff;
}

.setting .tabs .tabpane-content-microphone .icon-wapper .icon > svg,
.setting .tabs .tabpane-content-loudspeaker .icon-wapper .icon > svg {
    width: 100%;
    height: 100%;
}

/* 选择框和测试按钮 */
.setting .tabs .tabpane-content-camera .camera-col,
.setting .tabs .tabpane-content-microphone .microphone-col,
.setting .tabs .tabpane-content-loudspeaker .loudspeaker-col {
    height: 100%;
    width: 100%;
    padding: 0 1rem;
    margin: 1rem 0;
}

.setting .tabs .tabpane-content-camera .camera-col .select-wapper .ant-select,
.setting .tabs .tabpane-content-microphone .microphone-col .select-wapper .ant-select,
.setting .tabs .tabpane-content-loudspeaker .loudspeaker-col .select-wapper .ant-select {
    width: 100%;
}

.setting .tabs .tabpane-content-camera .camera-col .select-wapper .title,
.setting .tabs .tabpane-content-microphone .microphone-col .select-wapper .title,
.setting .tabs .tabpane-content-loudspeaker .loudspeaker-col .select-wapper .title {
    margin-bottom: 0.5rem;
}

.setting .tabs .tabpane-content-camera .camera-col .select-wapper .select,
.setting .tabs .tabpane-content-microphone .microphone-col .select-wapper .select,
.setting .tabs .tabpane-content-loudspeaker .loudspeaker-col .select-wapper .select {
    margin-bottom: 1rem;
}

.setting .tabs .tabpane-content-microphone .microphone-col .btn-wapper,
.setting .tabs .tabpane-content-loudspeaker .loudspeaker-col .btn-wapper {
    margin-top: 1rem;
}

.setting .tabs .tabpane-content-camera .tip-error {
    color: #ff4d4f;
}

.setting .tabs .tabpane-content-log .output-row {
    margin: 1rem 1rem 0.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background: #f5f5f5;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* trace */
.setting .tabs .tabpane-content-log .output-row .line1 {
    
}

/* debug */
.setting .tabs .tabpane-content-log .output-row .line2 {
    color: #ad6800;
}

/* log */
.setting .tabs .tabpane-content-log .output-row .line3 {
    color: #0050b3;
}

/* info */
.setting .tabs .tabpane-content-log .output-row .line4 {
    color: #237804;
}

/* warn */
.setting .tabs .tabpane-content-log .output-row .line5 {
    color: #9e1068;
}

/* error */
.setting .tabs .tabpane-content-log .output-row .line6 {
    color: #a8071a;
}

.setting .tabs .tabpane-content-log .upload {
    float: right;
    margin: 0.5rem 1rem;
}

.setting .tabs .wave .custom-1680 {
    height: 2rem!important;
    width: 0.5rem!important;
    margin: 0 0.125rem!important;
}

.setting .tabs .custom-1680 {
    margin: -1rem 0 0 -2.25rem!important;
}

/* 开始测试时的wave */
.setting .tabs .tabpane-content-microphone .wave {
    display: block;
    position: absolute;
    top: 35%;
    left: 50%;
    margin: -0.5rem 0 0 -1.5rem;
}

.setting .tabs .tabpane-content-loudspeaker .wave {
    display: block;
    position: absolute;
    top: 50%;
    left: 65%;
    margin: -0.5rem 0 0 -1.5rem;
}

.setting .tabs .tabpane-content-microphone .wave .rectangle-1,
.setting .tabs .tabpane-content-microphone .wave .rectangle-2,
.setting .tabs .tabpane-content-microphone .wave .rectangle-3,
.setting .tabs .tabpane-content-microphone .wave .rectangle-4,
.setting .tabs .tabpane-content-microphone .wave .rectangle-5,
.setting .tabs .tabpane-content-microphone .wave .rectangle-6,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-1,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-2,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-3,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-4,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-5,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-6 {
    position: relative;
    display: inline-block;
    background: #fff;
    height: 1rem;
    width: 0.25rem;
    margin: 0 0.125rem;
    animation: wave-start 2s ease-in-out infinite;
}

.setting .tabs .tabpane-content-microphone .wave .stop,
.setting .tabs .tabpane-content-loudspeaker .wave .stop {
    animation-play-state: paused;
}

.setting .tabs .tabpane-content-microphone .wave .start,
.setting .tabs .tabpane-content-loudspeaker .wave .start {
    animation-play-state: running;
}

.setting .tabs .tabpane-content-microphone .wave .rectangle-2,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-2 {
    animation-delay: 0.1s;
}

.setting .tabs .tabpane-content-microphone .wave .rectangle-3,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-3 {
    animation-delay: 0.2s;
}

.setting .tabs .tabpane-content-microphone .wave .rectangle-4,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-4 {
    animation-delay: 0.3s;
}
.setting .tabs .tabpane-content-microphone .wave .rectangle-5,
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-5 {
    animation-delay: 0.4s;
}

.setting .tabs .tabpane-content-microphone .wave .rectangle-6
.setting .tabs .tabpane-content-loudspeaker .wave .rectangle-6 {
    animation-delay: 0.5s;
}

@keyframes wave-start {
    0%,
    100% {
        transform: scaleY(1);
        background: #fff;
    }
    16.67% {
        transform: scaleY(3);
        background: #fff;
    }
    33.33% {
        transform: scaleY(1);
        background: #fff;
    }
    50% {
        transform: scaleY(3);
        background: #fff;
    }
    66.67% {
        transform: scaleY(1);
        background: #fff;
    }
    83.34% {
        transform: scaleY(3);
        background: #fff;
    }
} 
