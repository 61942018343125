

.slider-wapper {
    width: 100%;

    display: flex;
    bottom: 4rem;

    overflow: hidden;
    padding: 0 1rem;
}

.slider-wapper .slider {
    width: 100%;

    display: flex;

    background-color: rgba(0, 0, 0, 0.65);
    align-self: center;

    border-radius: 0.25rem;

    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.slider-wapper .slider .left,
.slider-wapper .slider .right {
    margin: 0 0.5rem;
    width: 2rem;
    align-self: center;
}

.slider-wapper .slider .right {
    /* margin-top: 0.5rem; */
    align-self: auto;
}

.slider-wapper .slider .left .icon,
.slider-wapper .slider .right .icon {
    margin: 1rem 0;
    font-size: 1.625rem;
    opacity: 0.65;
    color: #ffffff;
    border-radius: 50%;
}

.slider-wapper .slider .left .icon:hover,
.slider-wapper .slider .right .icon:hover {
    cursor: pointer;
    opacity: 1;
    transform: scale(1.2, 1.2);
    transition: all 0.2s;
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
}

.slider-wapper .slider .middle {
    width: inherit;
    display: flex;
    overflow: hidden;

    position: relative;
}

.slider-wapper .slider .middle .content {
    width: 3220px; /* 总共可以容纳的元素宽度 这里是322px * 10 */
    display: flex;
    align-self: center;
    left: 0px;
    position: absolute;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.slider-wapper .slider .middle .content .remote0,
.slider-wapper .slider .middle .content .remote1,
.slider-wapper .slider .middle .content .remote2,
.slider-wapper .slider .middle .content .remote3,
.slider-wapper .slider .middle .content .remote4,
.slider-wapper .slider .middle .content .remote5,
.slider-wapper .slider .middle .content .remote6,
.slider-wapper .slider .middle .content .remote7,
.slider-wapper .slider .middle .content .remote8 {
    height: 182px;
    width: 322px;

    background-color: rgba(0, 0, 0, 0.65);
    border: 1px solid #fff;
}
