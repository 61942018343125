.login {
    text-align: start;
}

.login .background {
    display: flex;
    opacity: 1;
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #096dd9;
    background-image: radial-gradient(circle, #b5f5ec, #096dd9); /* 标准的语法（必须放在最后） */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
}
.login .header {
    height: 2rem;
    text-align: right;
}

.login .header .version {
    font-weight: 500;
    padding: 0.25rem 1rem;
}

.login .footer {
    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.login .footer .copyright {
    font-weight: 500;
}

.login .control {
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);
}

.login .control .logo {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.login .control .title {
    font-weight: 600;
    vertical-align: middle;
}

.login .control .ant-card-head {
    border-bottom: 0;
}

.login .control .content .username, .control .content .username .password {
    margin-bottom: 1rem;
}

.login .control .content .title {
    margin-bottom: 0.5rem;
}

.login .control .content .ant-input-prefix {
    padding-right: 0.25rem;
}

.login .control .content .ant-input-lg {
    font-size: 14px;
    padding: 0 0.5rem;
}

.login .control .content .ant-form-item {
    margin-bottom: 0;
}

.login .control .content .login {
    margin: 2rem 0 1rem 0;
}
