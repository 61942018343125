
@keyframes bulingbuling {
    from { opacity: 1.0; }
    50% { opacity: 0.1; }
    to { opacity: 1.0; }
}

.yes,
.online,
.allow,
.connected {
    color: #73d13d;
}

.no,
.offline,
.forbidden,
.disconnected {
    color: #ff4d4f;
}

.connecting,
.reconnecting {
    color: #ffa940;
}

.init,
.invalid {
    color: #8c8c8c;
}

.tooltip-normal .ant-tooltip-inner,
.tooltip-error .ant-tooltip-inner,
.tooltip-success .ant-tooltip-inner,
.tooltip-error-permission .ant-tooltip-inner{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0, 0.65);
}

.tooltip-normal .ant-tooltip-arrow::before,
.tooltip-error .ant-tooltip-arrow::before,
.tooltip-success .ant-tooltip-arrow::before,
.tooltip-error-permission .ant-tooltip-arrow::before{
    background-color: rgb(255, 255, 255);
}

.tooltip-normal .ant-tooltip-arrow-content,
.tooltip-error .ant-tooltip-arrow-content,
.tooltip-success .ant-tooltip-arrow-content,
.tooltip-error-permission .ant-tooltip-arrow-content{
    background-color: rgb(255, 255, 255);
}

.tooltip-error .ant-tooltip-inner {
    color: #ff4d4f;
}

.tooltip-success .ant-tooltip-inner {
    color: #73d13d;
}

.tooltip-normal .detail .ant-descriptions-row > td {
    height: 2rem;
    padding-bottom: 0;
}

.tooltip-normal .detail .ant-descriptions-item-label {
    width: 7rem;
}

.tooltip-error-permission {
    max-width: 300px!important;
}

.tooltip-error-permission .title .ant-descriptions-title {
    margin-bottom: 0.5rem;
    color: #ff4d4f;
    font-size: 14px;
}

.tooltip-error-permission .ant-descriptions-item {
    padding-bottom: 0.5rem;
    height: 2rem;
}

.tooltip-error-permission .ant-descriptions-item-label {
    width: 20%;
}

.tooltip-error-permission .ant-descriptions-item-content {
    width: 80%;
}

.tooltip-error-permission .ant-descriptions-item-content .help {
    padding: 0 0.5rem;
    height: 100%;
    margin-left: auto;
}

.drawer-header .ant-drawer-body,
.drawer-footer .ant-drawer-body {
    padding: 0 1rem;
    overflow: hidden;
}

.drawer-header .ant-drawer-content-wrapper,
.drawer-footer .ant-drawer-content-wrapper{
    box-shadow: none!important;
}

.drawer-header .ant-drawer-content {
    background-color:transparent;
    background-image: linear-gradient(to bottom,rgba(0,0,0,.5),rgba(0,0,0,0));
}
.drawer-footer .ant-drawer-content {
    background-color:transparent;
    background-image: linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,.5));
}

.drawer-header .content,
.drawer-footer .content {
    height: 64px;
}

.drawer-header .left .status {
    display: -webkit-inline-box;
    position: relative;
    z-index: 100;
}

.drawer-header .left .status .tip {
    font-weight: 500;
}

.drawer-header .left .status .dot {
    position: relative;
    display: inline-block;

    width: 0.5rem;
    height: 0.5rem;
    vertical-align: middle;
    border-radius: 50%;
}

.drawer-header .left .status .permission-icon {
    position: relative;
    /* display: inline-block; */

    font-size: 24px;
    margin-right: 1rem;
    vertical-align: middle;
    animation: bulingbuling 600ms infinite;
    -webkit-animation: bulingbuling 600ms infinite;
}

.drawer-header .left .status .warning {
    position: relative;
    /* display: inline-block; */

    font-size: 24px;
    margin-right: 1rem;
    vertical-align: middle;
}

.drawer-header .left .profile {
    display: inline;
}

.drawer-header .left .profile .tips {
    color: #ff4d4f;
}

.drawer-header .left .profile .hidden {
    opacity: 0;
    height: 0;
    transition: all 1s cubic-bezier(.7,.3,.1,1);
}

.drawer-header .left .profile .show {
    opacity: 1;
    height: 100%;
    transition: all 1s cubic-bezier(.7,.3,.1,1);
}

.drawer-header .middle .theme {
    margin: 0 auto;
    font-size: 22px;
    color: #fff;
    display: table;
    /* user-select: none;  */
}

.drawer-header .middle .timer {
    color: #fff;
    display: table;
    margin: 0 auto;
    user-select: none; 
}

.dot-success {
    background-color: #73d13d;
}

.dot-failed {
    background-color: #ff4d4f;
    animation: bulingbuling 600ms infinite;
    -webkit-animation: bulingbuling 600ms infinite;
}

.drawer-header .status .description {
    vertical-align: middle;
    margin: 0 1rem;
    color: #fff;
    user-select: none; 
}

.drawer-header .right {
    /* display: contents; */
}

.drawer-header .right .icon {
    border-radius: 4px;
    font-size: 24px;
    transition: all 0.25s;
    text-align: right;
    float: right;
    padding: 8px;
    margin-right: 4px;
}

.drawer-header .right .icon:hover {
    cursor: pointer;
    background-color: #ffffff7F;
    -webkit-transition:background-color .5s ease-in-out;
    -moz-transition:background-color .5s ease-in-out;
    -o-transition:background-color .5s ease-in-out;
    -ms-transition:background-color .5s ease-in-out;
    transition:background-color .5s ease-in-out;
}


.drawer-footer .icons {
    /* display: contents; */
    text-align: center;
}

.drawer-footer .icons .main {
    margin: 0 1rem;
    border-radius: 50%;
    font-size: 40px;
    transition: all 0.25s;
}

.drawer-footer .icons .main:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
}

.drawer-footer .icons .offcut {
    margin: 0 1rem;
    font-size: 40px;
}

.drawer-footer .icons .offcut:hover {
    cursor: pointer;
}

.drawer-footer .icons .icon {
    border-radius: 4px;
    font-size: 24px;
    transition: all 0.25s;
    text-align: right;
    padding: 8px;
    margin-right: 4px;
}

.drawer-footer .icons .right {
    float: right;
}

.drawer-footer .icons .left {
    float: left;
}

.drawer-footer .icons .icon:hover {
    cursor: pointer;
    background-color: #ffffff7F;
    -webkit-transition:background-color .5s ease-in-out;
    -moz-transition:background-color .5s ease-in-out;
    -o-transition:background-color .5s ease-in-out;
    -ms-transition:background-color .5s ease-in-out;
    transition:background-color .5s ease-in-out;
}

.drawer-footer .icons .selected {
    background-color: #ffffff7F;
}

.drawer-right .ant-drawer-content {
    /* background-color: rgba(255,255,255,.9); */
    /* background-image: rgba(255,255,255,.1) */
}

.drawer-right .ant-drawer-header {
    padding: 16px;
    background: transparent;
}

.drawer-right .ant-drawer-body {
    padding: 0;
}
