

.detail .ant-modal-header .title .icon {
    margin-right: 1rem;
}

.detail .ant-modal-body {
    padding: 0.5rem 1.5rem;
}

.detail .ant-modal-body .descriptions .ant-descriptions-row > td {
    padding-bottom: 0;
}

.detail .ant-modal-body .descriptions .ant-descriptions-item-label {
    width: 5rem;
}