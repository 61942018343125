.content .descriptions .ant-descriptions-row > td {
    padding-bottom: 0;
}

.content .descriptions .ant-descriptions-item-label {
    width: 5rem;
}

.content .title {
    display: flex;
    align-items: center;
}

.content .title .icon {
    font-size: 16px;
    margin-bottom: 0.5em;
    line-height: 1.4;
    margin-right: 0.5rem;
}

.content .input-wapper {
    display: flex;
    margin: 1rem 0;
}

.content .input-wapper .input {
    border-radius: 4px 0 0 4px;
}

.content .input-wapper .input:focus {
    box-shadow: none;
}

.content .input-wapper .ok {
    border-radius: 0 4px 4px 0;
    font-weight: 500;
    box-shadow: none;
}

.content .exist-wapper {
    display: inline-block;
}

.content .exist-wapper .tip {
    color: #ff4d4f;
    margin-bottom: 0.5rem;
}

.content .exist-wapper .buttons {
    position: relative;
    float: right;
}

/* 会议选择框（选创建、选入会） */
.conference .content .select .wapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 3rem 1rem;
}

.conference .content .select .wapper:hover {
    cursor: pointer;
    background-color: #e6f7ff;
    border-radius: 4px;
}

.conference .content .select .icon {
    margin: 2rem;
    font-size: 100px;
}

/* 权限帮助 */
.permission-help .ant-modal-body {
    padding: 0;
}

.permission-help .content .help-enable-video{
    width: 100%;
    height: 100%;
}

/* 创建会议框 */


/* 加入会议框 */
.join .content .history-collapse {
    background: transparent;
    border-radius: 2px;
    border: 0px;
    overflow: hidden;
}

.join .content .history-collapse .ant-collapse-header {
    text-align: start;
    padding: 0 0 0 16px;
}

.join .content .history-collapse .ant-collapse-header .ant-collapse-arrow {
    left: 0;
    right: 0;
    text-align: start;
}

.join .content .history-collapse .ant-collapse-item {
    border-bottom: 0;
}

.join .content .history-collapse .ant-collapse-content {
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
}

.join .content .history-collapse .ant-collapse-content::-webkit-scrollbar {
    display: none;
}

.join .content .history-collapse .ant-collapse-content-box {
    padding: 0;
}

.join .content .ant-list {
    padding: 0.5rem 0;
}

.join .content .ant-list-split .ant-list-item {
    border-bottom: 0;
    position: relative;
}

.join .content .ant-list-split .ant-list-item::after {
    content: "";
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #dfdfdf;
    border-width: thin;
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
}

.join .content .ant-list-split .ant-list-item:last-child::after {
    border-bottom: 0;
}

.join .content .ant-list .ant-list-item {
    padding: 0.5rem;
}

.join .content .ant-list .ant-list-item:hover {
    /* cursor: pointer; */
    background-color: #e6f7ff;
    border-radius: 4px;
}

.join .content .ant-list .ant-list-item .ant-list-item-meta {
    align-items: flex-start;
}

.join .content .ant-list .ant-list-item .ant-list-item-meta .title {
    font-weight: 500;
    /* font-family: 'Times New Roman', Times, serif; */
}

.join .content .ant-list .ant-list-item .ant-list-item-meta .title .icon {
    margin-right: 1rem;
    font-size: 16px;
}

.join .content .ant-list .ant-list-item .ant-list-item-meta .title .code:hover {
    cursor: pointer;
    color: #40a9ff;
    text-decoration: underline;
}

.join .content .ant-list .ant-list-item .ant-list-item-meta .title .time-col {
    text-align: end;
}

.join .content .ant-list .ant-list-item .ant-list-item-action .icon {
    margin-left: 2px;
}

.join .content .ant-list .ant-list-item .operation-icon {
    font-size: 24px;
}

