

.infinite {
    overflow: auto;
}

.infinite .list .item {
    display: block;
    padding: 1rem;
}

.infinite .list .item:hover {
    background-color: #e6f7ff;
    border-radius: 4px;
}

.infinite .list .item .operation {
    opacity: 0;
    height: 0px;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.infinite .list .item:hover .operation {
    height: 2rem;
    margin-top: 1rem;
    border-top: dashed #40a9ff 1px;
    opacity: 1;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.infinite .list .item .content {
    display: flex;
    justify-content:space-between;
    width: 100%;
}

.infinite .list .item .content .title {
    text-align: left;
    margin-right: 0.5rem;
}

.infinite .list .item .content .title .img {
    height: 24px;
    width: 24px;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.infinite .list .item .content .title .name {
    font-size: 14px;
    line-height: 22px;
    vertical-align: middle;
}

.infinite .list .item .content .title .enable {
    opacity: 1; 
    color: #000;
    filter: alpha(opacity=100);
}

.infinite .list .item .content .title .disable {
    opacity: 0.6; 
    filter: alpha(opacity=60);
    color: #8c8c8c;
}

.infinite .list .item .content .icons .ant-tag {
    margin-right: 0;
}

.loading {
    position: absolute;
    bottom: 0.5rem;
    width: 100%;
    text-align: center;
}
